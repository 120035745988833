export const consultADoctor = {
    heading: 'Consult a Doctor',
    description: 'Search by disease or symptoms & find the best doctors in your city',
    exampleText: 'Ex: Abdominal Problems or Acne Treatment etc',
    searchText: 'Search',
}

export const BOOK_APPOINTMENT_HEADING_HOME_PAGE = 'Request Appointment'
export const CLINIC_HEADING_HOME_PAGE = 'Visit our Smart Clinic'
export const TREATMENT_HEADING_HOME_PAGE = 'Consult Doctor by Concern Area'
export const THERAPY_HEADING_HOME_PAGE = 'Avail World Class Ayurveda Therapies'
export const DOCTOR_HEADING_HOME_PAGE = 'Consult with Top Doctors'
export const BLOG_HEADING_HOME_PAGE = 'Recent Blogs'
export const HEALING_STORY_HEADING_HOME_PAGE = 'Healing Stories'
export const TRUSTED_BY_EXPERIENCED_DOCTOR_HEADING_HOME_PAGE = 'Trusted by Experienced Doctors'
export const FAQ_HEADING_HOME_PAGE = 'FAQs'
export const HOW_IT_WORK_STEPS_HEADING = 'How it works';

export const TREATMENT_LIST = [
    { src: '/images/expert-certified-ayurvedic-doctors.jpg', caption: 'Pain Management' },
    { src: '/images/expert-certified-ayurvedic-doctors.jpg', caption: 'Pain Management' },
    { src: '/images/expert-certified-ayurvedic-doctors.jpg', caption: 'Pain Management' },
    { src: '/images/expert-certified-ayurvedic-doctors.jpg', caption: 'Pain Management' },
    { src: '/images/expert-certified-ayurvedic-doctors.jpg', caption: 'Pain Management' },
    { src: '/images/expert-certified-ayurvedic-doctors.jpg', caption: 'Pain Management' },
]

export const PRACTITIONER_SECTION = {
    heading: 'Are you a practitioner?',
    benefits: ['Buy Medicine at Best Price', 'Connect with great Doctors', 'Participate in Clinical Discussion'],
    button: 'Join NirogStreet Now',
}

export const PRACTITIONER_COLOR_BOX_SECTION = [
    { id: 1, iconType: 'IconColorCart', heading: 'Buy Medicines', description: 'Your One-Stop Shop for the Best Medicine Prices!', url: '/medicines', urlTitle: 'Buy Now', automation: 'practitioner-card-buy-medicines' },
    { id: 2, iconType: 'IconColorPartner', heading: 'Nirog Partner Program', description: 'Healing Hub: Expert Meds Delivered to You!', url: '/reseller', urlTitle: 'Learn More', automation: 'practitioner-card-nirog-partner-program' },
    { id: 3, iconType: 'IconColorVaidya', heading: 'Clinical Discussions', description: 'Bridging Health & Harmony Through Conversations', url: '/feeds', urlTitle: 'Explore', automation: 'practitioner-card-clinical-discussions' },
    { id: 4, iconType: 'IconColorGrow', heading: 'Learn, Grow & Succeed', description: 'Crafting Success in Ayurveda through Webinar and Learning', url: '/webinar', urlTitle: 'Explore', automation: 'practitioner-card-learn-grow-succeed' },
]

export const HOW_IT_WORK_STEPS = [
    { id: 1, src: '/images/searchdr-request.svg', description: 'Search for a doctor, disease or symptom' },
    { id: 2, src: '/images/book-request.svg', description: 'Book a Consultation' },
    { id: 3, src: '/images/telemedicine-request.svg', description: 'Consult the doctor' },
    { id: 4, src: '/images/rx1.svg', description: 'Get medicines delivered with prescription' },
]

